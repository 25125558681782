import React, { memo } from 'react';
import { View, StyleSheet, Image, ViewProps } from 'react-native';

import { spacing } from '../constants/theme';
import { Text, Button } from '../core-ui';
import { error } from '../../../../assets';

type Props = {
  title?: string;
  message?: string;
  buttonTitle?: string;
  style?: ViewProps['style'];
  onPress?: () => void;
};

const ErrorMessage = memo((props: Props) => {
  let {
    title,
    message,
    buttonTitle = t(['Coba lagi', 'Retry']),
    style,
    onPress,
  } = props;

  let errorTitle = '';
  let errorMessage = '';
  if (!navigator.onLine) {
    errorTitle = t(['Tidak Ada Koneksi Internet', 'No Internet Connection']);
    errorMessage = t([
      'Mohon periksa koneksi internet Anda dan coba lagi.',
      'Please check your internet connection and try again.',
    ]);
  } else if (title && message) {
    errorTitle = title;
    errorMessage = message;
  } else {
    errorTitle = t(['Terjadi Kesalahan ', 'Something Went Wrong']);
    errorMessage = t([
      'Terjadi kesalahan tak terduga. Maaf atas ketidaknyamanannya.\nHarap coba lagi nanti.',
      'Unexpected error has occured. Sorry for the inconvinicence.\nPlease try again later.',
    ]);
  }

  return (
    <View style={[styles.root, style]}>
      <View style={styles.imageContainer}>
        <Image source={error} style={styles.image} />
      </View>
      <Text bold style={styles.title}>
        {errorTitle}
      </Text>
      <Text style={styles.message}>{errorMessage}</Text>
      {onPress && <Button large title={buttonTitle} onPress={onPress} />}
    </View>
  );
});

const styles = StyleSheet.create({
  root: { alignItems: 'center' },
  imageContainer: { paddingBottom: spacing.xxl },
  image: { width: 240, height: 147 },
  title: { paddingBottom: spacing.xxs },
  message: { textAlign: 'center', paddingBottom: spacing.l },
});

export default ErrorMessage;
