import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  View,
  StyleSheet,
  ScrollView,
  ActivityIndicator,
  TouchableWithoutFeedback,
  NativeScrollEvent,
} from 'react-native';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import { useDebounce } from 'use-debounce';
import { useQuery } from '@apollo/react-hooks';
import { useLocation } from 'react-router-dom';

import { Text, TextInput, Filter, FilterModal } from '../../core-ui';
import { spacing, colors } from '../../constants/theme';
import { toDdMmYyyy } from '../../../../helpers';
import { useAuth } from '../../../../context';
import { VERIFICATION_REQUESTS } from '../../graphql/queries';
import {
  VerificationRequests,
  VerificationRequestsVariables,
  VerificationRequests_requestVerificationAdvance_row,
} from '../../../../generated/VerificationRequests';
import {
  FilterStoreStatusVerifyInput,
  FilterUserStatusVerifyInput,
  TypeVerify,
  FilterViewDataInput,
} from '../../../../generated/globalTypes';
import { ErrorMessage } from '../../../../components';

type ListItemProps = {
  name: string;
  date: string;
  type: string;
  city: string;
  selected: boolean;
  onSelect: () => void;
};

const ListItem = (props: ListItemProps) => {
  let { name, date, type, city, selected, onSelect } = props;
  let selectedStyle =
    type === 'TOKO' ? styles.selectedToko : styles.selectedOwner;
  return (
    <TouchableWithoutFeedback onPress={onSelect}>
      <View
        data-cy="left-pane-item"
        style={[styles.listItem, selected && selectedStyle]}
      >
        <Text bold size="m" style={styles.largeBottomSpacing}>
          {name}
        </Text>
        <View
          style={[styles.row, styles.spaceBetween, styles.smallBottomSpacing]}
        >
          <Text size="xs" color="grey">
            {toDdMmYyyy(new Date(date))}
          </Text>
          <Text size="xs" color="grey">
            {city}
          </Text>
        </View>
        <Text size="xs" bold color={type === 'TOKO' ? 'blue' : 'orange'}>
          {t(['Tipe', 'Type'])}: {type === 'USER' ? 'OWNER' : type}
        </Text>
      </View>
    </TouchableWithoutFeedback>
  );
};

type Props = {
  shouldRefetch: boolean;
  selectedItemId: string | null;
  setSelectedItem: (
    value: VerificationRequests_requestVerificationAdvance_row | null,
  ) => void;
};

type LocationState = {
  data: VerificationRequests_requestVerificationAdvance_row;
};

type Location = {
  pathname: string;
  state: LocationState;
};

const LeftPane = (props: Props) => {
  const { shouldRefetch, selectedItemId, setSelectedItem } = props;
  const { id: adminId } = useAuth();
  let [searchTerm, setSearchTerm] = useState('');
  const rowsPerPage = 8;
  // let [page, setPage] = useState(0);

  let [debouncedSearchTerm] = useDebounce(searchTerm, 500);
  let [isFirstAssign, setIsFirstAssign] = useState(false);
  // let [oldTerm, setOldTerm] = useState('');
  let [filterOpen, setFilterOpen] = useState(false);
  let [filterVariables, setFilterVariables] = useState<
    VerificationRequestsVariables
  >({
    confirmDateInput: null,
    registerDateInput: null,
    storeFilter: null,
    storeStatus: undefined,
    type: null,
    userFilter: null,
    userStatus: undefined,
    viewData: FilterViewDataInput.ALL,
  });
  let [lastReturnDataLength, setLastReturnDataLength] = useState(10);
  let filterChanged = useRef(false);
  let isOnProcess = useRef(false);
  let changeData = useRef(false);
  let page = useRef(0);
  const [filterPlaceholder, setFilterPlaceholder] = useState('');
  const [loadingNext, setLoadingNext] = useState(false);
  let [filteredData, setFilteredData] = useState<
    Array<VerificationRequests_requestVerificationAdvance_row>
  >([]);
  const location: Location = useLocation();
  const prevData = useRef<VerificationRequests_requestVerificationAdvance_row | null>(
    null,
  );

  let { data, error, loading, refetch } = useQuery<
    VerificationRequests,
    VerificationRequestsVariables
  >(VERIFICATION_REQUESTS, {
    variables: {
      adminId,
      pagination: {
        skip: 0,
        first: rowsPerPage,
      },

      // THIS LINE BELOW IS TEMPORARY FIX AND SHOULD BE CHANGED IN NEAR TIME, THIS WILL BE TEMPORARILY USED UNTIL BETTER SOLUTION COMES
      // REASON IS THE FETCH HAPPENS 2-3 TIMES BECAUSE OF USE QUERY AND USEEFFECT AT 300ISH LINE, THE FILTER IS RUN TWICE
      // SINCE THE FIRST ONE FOR THE ASSIGNMENT OF STATE AND SECOND ONE IS NOT FOUND YET
      userStatus: [
        FilterUserStatusVerifyInput.UNVERIFIED,
        FilterUserStatusVerifyInput.WAITING,
      ],
      storeStatus: [
        FilterStoreStatusVerifyInput.UNVERIFIED,
        FilterStoreStatusVerifyInput.WAITING,
        FilterStoreStatusVerifyInput.REVALIDATION,
      ],
    },
    notifyOnNetworkStatusChange: true,
  });

  let refetchData = useCallback(
    async (skip?: number) => {
      if (isFirstAssign) {
        isOnProcess.current = true;
      }

      let getUserFilter = (
        filter: VerificationRequestsVariables,
        target: string,
      ) => {
        if (filter.type === TypeVerify.TOKO) {
          return undefined;
        } else {
          if (filter.userFilter) {
            let name = filterVariables.userFilter?.name ?? target.trim();
            let email = filterVariables.userFilter?.email;
            let accNumber = filterVariables.userFilter?.accNumber;
            let phoneNumber = filterVariables.userFilter?.phoneNumber;
            return {
              name,
              email,
              accNumber,
              phoneNumber,
            };
          } else if (target.trim() !== '') {
            return {
              name: target.trim(),
              email: undefined,
              accNumber: undefined,
              phoneNumber: undefined,
            };
          } else {
            return undefined;
          }
        }
      };

      let getStoreFilter = (
        filter: VerificationRequestsVariables,
        target: string,
      ) => {
        if (filter.type === TypeVerify.USER || filter.type == null) {
          return undefined;
        } else {
          if (filter.storeFilter) {
            let storeName = filter.storeFilter?.storeName ?? target.trim();
            let address = filter.storeFilter?.address ?? undefined;
            let cityId = filter.storeFilter?.cityId ?? undefined;
            let districtId = filter.storeFilter?.districtId ?? undefined;
            let provinceId = filter.storeFilter?.provinceId ?? undefined;
            let storeCategoryId =
              filter.storeFilter?.storeCategoryId ?? undefined;
            let storeCode = filter.storeFilter?.storeCode ?? undefined;
            let storeEmail = filter.storeFilter?.storeEmail ?? undefined;
            let storePhoneNumber =
              filter.storeFilter?.storePhoneNumber ?? undefined;
            let urbanVillageId =
              filter.storeFilter?.urbanVillageId ?? undefined;

            return {
              storeName,
              address,
              cityId,
              districtId,
              provinceId,
              storeCategoryId,
              storeCode,
              storeEmail,
              storePhoneNumber,
              urbanVillageId,
            };
          } else if (target.trim() !== '') {
            return {
              storeName: target.trim(),
              address: undefined,
              cityId: undefined,
              districtId: undefined,
              provinceId: undefined,
              storeCategoryId: undefined,
              storeCode: undefined,
              storeEmail: undefined,
              storePhoneNumber: undefined,
              urbanVillageId: undefined,
            };
          } else {
            return undefined;
          }
        }
      };

      let userFilter = getUserFilter(filterVariables, debouncedSearchTerm);
      let storeFilter = getStoreFilter(filterVariables, debouncedSearchTerm);

      let { data } = await refetch({
        ...filterVariables,
        userFilter,
        storeFilter,
        adminId,
        userStatus: [
          FilterUserStatusVerifyInput.UNVERIFIED,
          FilterUserStatusVerifyInput.WAITING,
        ],
        storeStatus: [
          FilterStoreStatusVerifyInput.UNVERIFIED,
          FilterStoreStatusVerifyInput.WAITING,
          FilterStoreStatusVerifyInput.REVALIDATION,
        ],
        pagination: {
          skip: filterChanged.current
            ? 0
            : (skip ?? page.current) * rowsPerPage,
          first: rowsPerPage,
        },
      });

      changeData.current = true;
      if (data && data.requestVerificationAdvance) {
        let filData = data.requestVerificationAdvance.row;
        setLastReturnDataLength(filData.length);

        if (filterChanged.current || filteredData.length === 0) {
          setIsFirstAssign(true);
          setFilteredData(filData);
          setLoadingNext(false);
          filterChanged.current = false;
        } else if (filteredData.length !== 0) {
          setFilteredData([...filteredData, ...filData]);
          filterChanged.current = false;
          setLoadingNext(false);
        }
      }
      filterChanged.current = false;

      if (isFirstAssign) {
        isOnProcess.current = false;
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      filteredData,
      setFilteredData,
      filterVariables,
      debouncedSearchTerm,
      isFirstAssign,
    ],
  );

  useEffect(() => {
    changeData.current = false;
  }, [filteredData]);

  useEffect(() => {
    page.current = 0;
    setSelectedItem(null);
    filterChanged.current = true;
    refetchData(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVariables, shouldRefetch]);

  useEffect(() => {
    setSelectedItem(null);
    if (debouncedSearchTerm === '') {
      filterChanged.current = true;
      page.current = 0;
      refetchData(0);
    } else if (debouncedSearchTerm.trim() !== '') {
      filterChanged.current = true;
      page.current = 0;
      refetchData(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (location.state) {
      let { data: navigationData } = location.state;
      if (
        (prevData.current == null ||
          (prevData.current && navigationData.id !== prevData.current.id)) &&
        data &&
        data.requestVerificationAdvance.row.findIndex(
          ({ id }) => id === navigationData.id,
        ) >= 0
      ) {
        setSelectedItem(navigationData);
        prevData.current = navigationData;
      }
    }
  }, [data, location.state, setSelectedItem]);

  let onSetFilterVariables = useCallback(
    (params: VerificationRequestsVariables) => {
      setFilterVariables(params);
      // filterChanged.current = true;
      // refetchData(0);
    },
    [setFilterVariables],
  );

  let onCloseFilter = useCallback(() => setFilterOpen(false), [setFilterOpen]);

  const isCloseToBottom = ({
    layoutMeasurement,
    contentOffset,
    contentSize,
  }: NativeScrollEvent) => {
    const paddingToBottom = 10;

    return (
      layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom
    );
  };

  return (
    <View style={styles.root}>
      <View style={styles.header}>
        <View style={[styles.row, styles.mediumBottomSpacing]}>
          <Text bold size="xl">
            {t(['Sedang Dikerjakan', 'Work In Progress'])}
          </Text>
          <View style={styles.badge}>
            <Text color="white" size="xs">
              {filteredData.length}
            </Text>
          </View>
        </View>
        <TextInput
          value={searchTerm}
          onChangeText={(value: string) => {
            setSearchTerm(value);
          }}
          placeholder={t(['Cari', 'Search'])}
          rightNode={<SearchRoundedIcon htmlColor={colors.leftPane.icon} />}
          containerStyle={styles.mediumBottomSpacing}
        />
        <Filter
          onPress={() => setFilterOpen(true)}
          placeholder={filterPlaceholder}
        />
      </View>
      <ScrollView
        onScroll={({ nativeEvent }) => {
          if (
            isCloseToBottom(nativeEvent) &&
            !(lastReturnDataLength < 8) &&
            !isOnProcess.current &&
            !filterChanged.current &&
            !changeData.current
          ) {
            setLoadingNext(true);
            refetchData((page.current += 1));
          }
        }}
      >
        {error && (
          <ErrorMessage
            action={t([
              'mengambil data permintaan verifikasi',
              'retrieve the verification request data',
            ])}
            // onPress={refetchData}
            style={styles.center}
          />
        )}
        {loading && !loadingNext && <ActivityIndicator style={styles.center} />}
        {!loading && !error && !filteredData.length && (
          <Text style={styles.center}>{t(['Tidak ada data', 'No data'])}</Text>
        )}
        {(!loading ||
          filteredData.length !== 0 ||
          filterChanged.current === false) &&
          filteredData.map((verificationRequest) => {
            const { id, type, store, user, createdAt } = verificationRequest;
            return (
              <ListItem
                key={id}
                name={
                  (type === TypeVerify.TOKO
                    ? store?.storeManagerBulk?.storeName
                    : user?.profile?.name) || ''
                }
                date={createdAt}
                type={type}
                city={store?.storeManagerBulk?.city || ''}
                selected={id === selectedItemId}
                onSelect={() => setSelectedItem(verificationRequest)}
              />
            );
          })}

        {loading && loadingNext && (
          <ActivityIndicator style={[styles.center, { paddingBottom: 10 }]} />
        )}
      </ScrollView>
      <FilterModal
        wip
        fullWidth
        open={filterOpen}
        setFilter={onSetFilterVariables}
        setPlaceholder={setFilterPlaceholder}
        onClose={onCloseFilter}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderColor: colors.border.primary,
  },
  header: {
    paddingHorizontal: spacing.l,
    paddingVertical: spacing.m,
    borderBottomWidth: 1,
    borderColor: colors.border.primary,
    backgroundColor: colors.background.white,
  },
  smallBottomSpacing: {
    paddingBottom: spacing.xxs,
  },
  mediumBottomSpacing: {
    paddingBottom: spacing.m,
  },
  largeBottomSpacing: {
    paddingBottom: spacing.l,
  },
  badge: {
    width: 20,
    height: 20,
    borderRadius: 10,
    backgroundColor: colors.leftPane.badge,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: spacing.xxs,
  },
  listItem: {
    padding: spacing.l,
    borderBottomWidth: 1,
    borderColor: colors.border.primary,
    backgroundColor: colors.background.white,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  selectedToko: {
    backgroundColor: colors.leftPane.toko.background,
    borderLeftWidth: 4,
    borderLeftColor: colors.leftPane.toko.leftBorder,
  },
  selectedOwner: {
    backgroundColor: colors.leftPane.owner.background,
    borderLeftWidth: 4,
    borderLeftColor: colors.leftPane.owner.leftBorder,
  },
  center: {
    alignSelf: 'center',
    paddingTop: spacing.l,
  },
});

export default LeftPane;
